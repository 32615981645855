body {
	padding-bottom: 200px;

}
.database_message {
	color: #999;
}
.result_table td .bar{
	height: 18px;
	display:inline-block;
	margin-right: 5px;
	vertical-align:middle;
	background: #6bba70; /* Old browsers */
	background: -moz-linear-gradient(top, #6bba70 0%, #255e2b 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#6bba70), color-stop(100%,#255e2b)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #6bba70 0%,#255e2b 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #6bba70 0%,#255e2b 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, #6bba70 0%,#255e2b 100%); /* IE10+ */
	background: linear-gradient(top, #6bba70 0%,#255e2b 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6bba70', endColorstr='#255e2b',GradientType=0 ); /* IE6-9 */
}
.result_table thead th {
	background-color: white;
}

.currency {
	text-align:right;
}

.right {
	text-align:right !important;
}
.left {
	text-align:left !important;
}
.center {
	text-align:center !important;
}
.show_query, #show_time_estimate, #query_holder {
	font-size: .8em;
}
.waiting div {
	text-align: center;
	padding-bottom: 25px;

}
pre {
	white-space: pre-wrap;			/* css-3 */
	white-space: -moz-pre-wrap;		/* Mozilla, since 1999 */
	white-space: -pre-wrap;			/* Opera 4-6 */
	white-space: -o-pre-wrap;		/* Opera 7 */
	word-wrap: break-word;			/* Internet Explorer 5.5+ */
}
#variable_form label {
	vertical-align:top;
}

@media print {
	body {
		padding-top: 0 !important;
	}
	.navbar-fixed-top ,
    #variable_form .btn-primary,
    #content .export_links,
    #report_content a[data-role="button"],
    a[data-role="button"][data-target="#time_estimate_holder"],
    #report_content .dataTables_filter {
		display: none !important;
	}
}


.white {
	color: #fff !important;
}

.bg-white {
	background: #fff !important;
}
.track {
	background: #f4f4f4;
}

.delivered {
	background: #6AE11E;
	width: 100% !important;
}

.cancelled {
	width: 100% !important;
	background: #E11E6A;
	color:#fff;
}

.other {
	width: 100% !important;
	background:#1E6AE1;
	color:#fff;
}

.track {
	background: #f4f4f4;
}
.p-component {
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
font-size: .9rem;
font-weight: normal;
}
.table thead tr th {
font-size: .9rem;
}
body {
touch-action: manipulation;
}

.bg-p {
	background: #a2329c !important;
	color: #fff;
}

.bg-p input:focus {
	color: #fff !important;
}
