.sidebar .user .user-info .caret {
position: absolute;
top: 8px;
right: 15px;
}
.sidebar .nav {
margin-top: 15px;
display: block;
}
.nav li .dropdown-menu a, .sidebar .nav li a {
margin: 10px 15px 0;
border-radius: 3px;
color: #252b33;
padding-left: 10px;
padding-right: 10px;
text-transform: capitalize;
font-size: 13px;
}

.tim-typo{
  padding-left: 25%;
  margin-bottom: 40px;
  position: relative;
  width: 100%;
}
.tim-typo .tim-note{
  bottom: 5px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
}

.sidebar .user {
    padding-bottom: 20px;
    margin: 20px auto 0;
    position:relative
}

.sidebar .user:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 15px;
    height: 1px;
    width: calc(100% - 30px);
    background-color:hsla(0, 0%, 70.6%, .3)
}

.sidebar .user .photo {
    width: 34px;
    height: 34px;
    overflow: hidden;
    float: left;
    z-index: 5;
    margin-right: 11px;
    border-radius: 50%;
    margin-left: 23px;
    box-shadow:0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2)
}

.sidebar .user .photo img {
    width:100%
}

.sidebar .user a {
    color: #252b33;
    padding: .5rem 15px;
    white-space:nowrap
}

.sidebar .user .user-info > a {
    display: block;
    line-height: 18px;
    font-size:14px
}

.sidebar .user .user-info > a > span {
    display: block;
    position: relative;
    opacity:1
}

.sidebar .user .user-info .caret {
    position: absolute;
    top: 8px;
    right:15px
}


.sidebar .nav {
    margin-top: 15px;
    display:block
}

.sidebar .nav .caret {
    margin-top: 13px;
    position: absolute;
    right:6px
}

.sidebar .nav li > a:focus, .sidebar .nav li > a:hover {
    background-color: transparent;
    outline:none
}

.sidebar .nav li:first-child > a {
    margin:0 5px
}

.sidebar .nav li.active > [data-toggle=collapse], .sidebar .nav li .dropdown-menu a:focus, .sidebar .nav li .dropdown-menu a:hover, .sidebar .nav li:hover > a {
	background-color: #000000;
	color: #fff;
	box-shadow: none;
}

.sidebar .nav li.active > [data-toggle=collapse] i {
    color:#a9afbb
}

.sidebar .nav li.active > a, .sidebar .nav li.active > a i {
    color:#fff
}

.sidebar .nav li.separator {
    margin:15px 0
}

.sidebar .nav li.separator:after {
    width: calc(100% - 30px);
    content: "";
    position: absolute;
    height: 1px;
    left: 15px;
    background-color:hsla(0, 0%, 70.6%, .3)
}

.sidebar .nav li.separator + li {
    margin-top:31px
}

.sidebar .nav p {
    margin: 0;
    line-height: 30px;
    font-size: 14px;
    position: relative;
    display: block;
    height: auto;
    white-space:nowrap
}

.sidebar .nav i {
    font-size: 24px;
    float: left;
    margin-right: 15px;
    line-height: 30px;
    width: 30px;
    text-align: center;
    color:#4e4e4e
}

.sidebar .nav li .dropdown-menu a, .sidebar .nav li a {
    margin: 10px 5px 0;
    border-radius: 3px;
    color: #0e0e0e;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: capitalize;
    font-size:14px
}

.sidebar .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position:50%
}

.sidebar .sidebar-background:after {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    background: #fff;
    opacity:.93
}

.sidebar .logo {
    padding: 15px 0;
    margin: 0;
    display: block;
    position: relative;
    z-index:4
}


.sidebar .sidebar-wrapper .user .user-info [data-toggle=collapse] ~ div > ul > li > a .sidebar-normal, .sidebar .sidebar-wrapper > .nav [data-toggle=collapse] ~ div > ul > li > a .sidebar-normal {
    margin: 0;
    position: relative;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    white-space: nowrap;
    display:block
}
.sidebar .sidebar-wrapper .user .user-info [data-toggle=collapse] ~ div > ul > li > a .sidebar-mini, .sidebar .sidebar-wrapper > .nav [data-toggle=collapse] ~ div > ul > li > a .sidebar-mini {
    text-transform: uppercase;
    width: 30px;
    margin-right: 15px;
    text-align: center;
    letter-spacing: 1px;
    position: relative;
    float: left;
    display:inherit
}

.sidebar .sidebar-wrapper .user .user-info [data-toggle=collapse]~div>ul>li>a .sidebar-mini, .sidebar .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-mini {
text-transform: uppercase;
width: 30px;
margin-right: 15px;
text-align: center;
letter-spacing: 1px;
position: relative;
}
.sidebar .sidebar-wrapper .user .user-info [data-toggle=collapse]~div>ul>li>a .sidebar-normal, .sidebar .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-normal {
margin: 0;
position: relative;
-webkit-transform: translateX(0);
transform: translateX(0);
opacity: 1;
white-space: nowrap;
display: block;
}

.pagination .paginate_button a, .pagination > .page-item > .page-link.mat-button, .pagination > .page-item > span {
    border: 0;
    border-radius: 30px !important;
    -webkit-transition: all .3s;
    transition: all .3s;
    padding: 0 11px;
    margin: 0 3px;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    color: #999;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    background: transparent;
    text-align:center
}

.pagination .paginate_button a:focus, .pagination .paginate_button a:hover, .pagination > .page-item > .page-link.mat-button:focus, .pagination > .page-item > .page-link.mat-button:hover, .pagination > .page-item > span:focus, .pagination > .page-item > span:hover {
    color:#999
}

.pagination .paginate_button a {
    position: relative;
    display:block
}

.pagination .paginate_button.active a, .pagination > .page-item.active > a.mat-button, .pagination > .page-item.active > span {
    color:#999
}

.pagination .paginate_button.active a, .pagination .paginate_button.active a:focus, .pagination .paginate_button.active a:hover, .pagination > .page-item.active > a.mat-button, .pagination > .page-item.active > a.mat-button:focus, .pagination > .page-item.active > a.mat-button:hover, .pagination > .page-item.active > span, .pagination > .page-item.active > span:focus, .pagination > .page-item.active > span:hover {
    background-color: #9c27b0;
    border-color: #9c27b0;
    color: #fff;
    box-shadow:0 4px 5px 0 rgba(156, 39, 176, .14), 0 1px 10px 0 rgba(156, 39, 176, .12), 0 2px 4px -1px rgba(156, 39, 176, .2)
}

.pagination.pagination-info .paginate_button.active a, .pagination.pagination-info .paginate_button.active a:focus, .pagination.pagination-info .paginate_button.active a:hover, .pagination.pagination-info > .page-item.active > a, .pagination.pagination-info > .page-item.active > a:focus, .pagination.pagination-info > .page-item.active > a:hover, .pagination.pagination-info > .page-item.active > span, .pagination.pagination-info > .page-item.active > span:focus, .pagination.pagination-info > .page-item.active > span:hover {
    background-color: #00bcd4;
    border-color: #00bcd4;
    box-shadow:0 4px 5px 0 rgba(0, 188, 212, .14), 0 1px 10px 0 rgba(0, 188, 212, .12), 0 2px 4px -1px rgba(0, 188, 212, .2)
}

.pagination.pagination-success .paginate_button.active a, .pagination.pagination-success .paginate_button.active a:focus, .pagination.pagination-success .paginate_button.active a:hover, .pagination.pagination-success > .page-item.active > a, .pagination.pagination-success > .page-item.active > a:focus, .pagination.pagination-success > .page-item.active > a:hover, .pagination.pagination-success > .page-item.active > span, .pagination.pagination-success > .page-item.active > span:focus, .pagination.pagination-success > .page-item.active > span:hover {
    background-color: #4caf50;
    border-color: #4caf50;
    box-shadow:0 4px 5px 0 rgba(76, 175, 80, .14), 0 1px 10px 0 rgba(76, 175, 80, .12), 0 2px 4px -1px rgba(76, 175, 80, .2)
}

.pagination.pagination-warning .paginate_button.active a, .pagination.pagination-warning .paginate_button.active a:focus, .pagination.pagination-warning .paginate_button.active a:hover, .pagination.pagination-warning > .page-item.active > a, .pagination.pagination-warning > .page-item.active > a:focus, .pagination.pagination-warning > .page-item.active > a:hover, .pagination.pagination-warning > .page-item.active > span, .pagination.pagination-warning > .page-item.active > span:focus, .pagination.pagination-warning > .page-item.active > span:hover {
    background-color: #ff9800;
    border-color: #ff9800;
    box-shadow:0 4px 5px 0 rgba(255, 152, 0, .14), 0 1px 10px 0 rgba(255, 152, 0, .12), 0 2px 4px -1px rgba(255, 152, 0, .2)
}

.pagination.pagination-danger .paginate_button.active a, .pagination.pagination-danger .paginate_button.active a:focus, .pagination.pagination-danger .paginate_button.active a:hover, .pagination.pagination-danger > .page-item.active > a, .pagination.pagination-danger > .page-item.active > a:focus, .pagination.pagination-danger > .page-item.active > a:hover, .pagination.pagination-danger > .page-item.active > span, .pagination.pagination-danger > .page-item.active > span:focus, .pagination.pagination-danger > .page-item.active > span:hover {
    background-color: #f44336;
    border-color: #f44336;
    box-shadow:0 4px 5px 0 rgba(244, 67, 54, .14), 0 1px 10px 0 rgba(244, 67, 54, .12), 0 2px 4px -1px rgba(244, 67, 54, .2)
}

.mat-button:hover .mat-button-focus-overlay, .mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: 0;
    background-color:transparent !important
}
table.dataTable {
    clear: both;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    max-width: none !important;
    border-collapse: separate !important;
    border-spacing:0
}

table.dataTable td, table.dataTable th {
    box-sizing:content-box
}

table.dataTable td.dataTables_empty, table.dataTable th.dataTables_empty {
    text-align:center
}

table.dataTable.nowrap td, table.dataTable.nowrap th {
    white-space:nowrap
}

div.dataTables_wrapper div.dataTables_length label {
    font-weight: 400;
    text-align: left;
    white-space:nowrap
}

div.dataTables_wrapper div.dataTables_length select {
    width: auto;
    display:inline-block
}

div.dataTables_wrapper div.dataTables_filter {
    text-align:right
}

div.dataTables_wrapper div.dataTables_filter label {
    font-weight: 400;
    white-space: nowrap;
    text-align:left
}

div.dataTables_wrapper div.dataTables_filter input {
    margin-left: .5em;
    display: inline-block;
    width:auto
}

div.dataTables_wrapper div.dataTables_info {
    padding-top: .85em;
    white-space:nowrap
}

div.dataTables_wrapper div.dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align:right
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    -webkit-box-pack: end;
    justify-content:flex-end
}

div.dataTables_wrapper div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding:1em 0
}

table.dataTable thead > tr > td.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc {
    padding-right:30px
}

table.dataTable thead > tr > td:active, table.dataTable thead > tr > th:active {
    outline:none
}

table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_asc_disabled, table.dataTable thead .sorting_desc, table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    position:relative
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc_disabled:after, table.dataTable thead .sorting_desc_disabled:before {
    position: absolute;
    bottom: .9em;
    display: block;
    opacity:.3
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc_disabled:before {
    right: 1em;
    content: "\2191"
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_desc_disabled:after {
    right: .5em;
    content: "\2193"
}

table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:after {
    opacity:1
}

table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
    opacity:0
}

div.dataTables_scrollHead table.dataTable {
    margin-bottom:0 !important
}

div.dataTables_scrollBody table {
    border-top: none;
    margin-top: 0 !important;
    margin-bottom:0 !important
}

div.dataTables_scrollBody table thead .sorting:after, div.dataTables_scrollBody table thead .sorting:before, div.dataTables_scrollBody table thead .sorting_asc:after, div.dataTables_scrollBody table thead .sorting_asc:before, div.dataTables_scrollBody table thead .sorting_desc:after, div.dataTables_scrollBody table thead .sorting_desc:before {
    display:none
}

div.dataTables_scrollBody table tbody tr:first-child td, div.dataTables_scrollBody table tbody tr:first-child th {
    border-top:none
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
    box-sizing:content-box
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
    margin-top: 0 !important;
    border-top:none
}

@media screen and (max-width: 767px) {
    div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_paginate {
        text-align:center
    }
}

table.dataTable.table-sm > thead > tr > th {
    padding-right:20px
}

table.dataTable.table-sm .sorting:before, table.dataTable.table-sm .sorting_asc:before, table.dataTable.table-sm .sorting_desc:before {
    top: 5px;
    right:.85em
}

table.dataTable.table-sm .sorting:after, table.dataTable.table-sm .sorting_asc:after, table.dataTable.table-sm .sorting_desc:after {
    top:5px
}

table.table-bordered.dataTable td, table.table-bordered.dataTable th {
    border-left-width:0
}

table.table-bordered.dataTable td:last-child, table.table-bordered.dataTable th:last-child {
    border-right-width:0
}

div.dataTables_scrollHead table.table-bordered, table.table-bordered.dataTable tbody td, table.table-bordered.dataTable tbody th {
    border-bottom-width:0
}

div.table-responsive > div.dataTables_wrapper > div.row {
    margin:0
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
    padding-left:0
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
    padding-right: 0
}

.pagination {
    display: -webkit-box;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius:.25rem
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: 0;
    line-height: 1.25;
    color: #9c27b0;
    background-color: transparent;
    border:0 solid #dee2e6
}

.page-link:hover {
    z-index: 2;
    color: #641971;
    text-decoration: none;
    background-color: #e9ecef;
    border-color:#dee2e6
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow:0 0 0 .2rem rgba(33, 150, 243, .25)
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius:.25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius:.25rem
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #2196f3;
    border-color:#2196f3
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: transparent;
    border-color:#dee2e6
}

.pagination-lg .page-link {
    padding: .75rem 0;
    font-size: 1.25rem;
    line-height:1.5
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius:.3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius:.3rem
}

.pagination-sm .page-link {
    padding: .25rem 0;
    font-size: .875rem;
    line-height:1.5
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius:.2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius:.2rem
}

.places-buttons .btn {
    margin-bottom:30px
}

.pagination > .page-item .page-link.mat-button .mat-button-ripple {
    border-radius:30px
}

.pagination .paginate_button a, .pagination > .page-item > .page-link.mat-button, .pagination > .page-item > span {
    border: 0;
    border-radius: 30px !important;
    -webkit-transition: all .3s;
    transition: all .3s;
    padding: 0 11px;
    margin: 0 3px;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    color: #999;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    background: transparent;
    text-align:center
}

.control-box {
	height: 150px;
	overflow: auto;
	border: 1px solid #dee2e6;
}
/* Styles for tab labels */
.mat-tab-label {
	color: rgb(0, 0, 0);
	font-weight: 500;
	opacity: 1 !important;
}

/* Styles for the active tab label */
.mat-tab-label.mat-tab-label-active {
	color: black;
	font-weight: 700;
	opacity: 1 !important;
}

.list-group {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	border-radius:.375rem
}

.list-group-numbered {
	list-style-type: none;
	counter-reset:a
}

.list-group-numbered > li:before {
	content: counters(a, ".") ". ";
	counter-increment:a
}

.list-group-item-action {
	width: 100%;
	color: #495057;
	text-align:inherit
}

.list-group-item-action:focus, .list-group-item-action:hover {
	z-index: 1;
	color: #495057;
	text-decoration: none;
	background-color:#f8f9fa
}

.list-group-item-action:active {
	color: #7b809a;
	background-color:#e9ecef
}

.list-group-item {
	position: relative;
	display: block;
	padding: .5rem 1rem;
	color: inherit;
	background-color: #fff;
	border:1px solid rgba(0, 0, 0, .125)
}

.list-group-item:first-child {
	border-top-left-radius: inherit;
	border-top-right-radius:inherit
}

.list-group-item:last-child {
	border-bottom-right-radius: inherit;
	border-bottom-left-radius:inherit
}

.list-group-item.disabled, .list-group-item:disabled {
	color: #6c757d;
	pointer-events: none;
	background-color:#fff
}

.list-group-item.active {
	z-index: 2;
	color: #fff;
	background-color: #e91e63;
	border-color:#e91e63
}

.list-group-item + .list-group-item {
	border-top-width:0
}

.list-group-item + .list-group-item.active {
	margin-top: -1px;
	border-top-width:1px
}

.list-group-horizontal {
	flex-direction:row
}

.list-group-horizontal > .list-group-item:first-child {
	border-bottom-left-radius: .375rem;
	border-top-right-radius:0
}

.list-group-horizontal > .list-group-item:last-child {
	border-top-right-radius: .375rem;
	border-bottom-left-radius:0
}

.list-group-horizontal > .list-group-item.active {
	margin-top:0
}

.list-group-horizontal > .list-group-item + .list-group-item {
	border-top-width: 1px;
	border-left-width:0
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
	margin-left: -1px;
	border-left-width:1px
}

@media (min-width: 576px) {
	.list-group-horizontal-sm {
		flex-direction:row
	}

	.list-group-horizontal-sm > .list-group-item:first-child {
		border-bottom-left-radius: .375rem;
		border-top-right-radius:0
	}

	.list-group-horizontal-sm > .list-group-item:last-child {
		border-top-right-radius: .375rem;
		border-bottom-left-radius:0
	}

	.list-group-horizontal-sm > .list-group-item.active {
		margin-top:0
	}

	.list-group-horizontal-sm > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width:0
	}

	.list-group-horizontal-sm > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width:1px
	}
}

@media (min-width: 768px) {
	.list-group-horizontal-md {
		flex-direction:row
	}

	.list-group-horizontal-md > .list-group-item:first-child {
		border-bottom-left-radius: .375rem;
		border-top-right-radius:0
	}

	.list-group-horizontal-md > .list-group-item:last-child {
		border-top-right-radius: .375rem;
		border-bottom-left-radius:0
	}

	.list-group-horizontal-md > .list-group-item.active {
		margin-top:0
	}

	.list-group-horizontal-md > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width:0
	}

	.list-group-horizontal-md > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width:1px
	}
}

@media (min-width: 992px) {
	.list-group-horizontal-lg {
		flex-direction:row
	}

	.list-group-horizontal-lg > .list-group-item:first-child {
		border-bottom-left-radius: .375rem;
		border-top-right-radius:0
	}

	.list-group-horizontal-lg > .list-group-item:last-child {
		border-top-right-radius: .375rem;
		border-bottom-left-radius:0
	}

	.list-group-horizontal-lg > .list-group-item.active {
		margin-top:0
	}

	.list-group-horizontal-lg > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width:0
	}

	.list-group-horizontal-lg > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width:1px
	}
}

@media (min-width: 1200px) {
	.list-group-horizontal-xl {
		flex-direction:row
	}

	.list-group-horizontal-xl > .list-group-item:first-child {
		border-bottom-left-radius: .375rem;
		border-top-right-radius:0
	}

	.list-group-horizontal-xl > .list-group-item:last-child {
		border-top-right-radius: .375rem;
		border-bottom-left-radius:0
	}

	.list-group-horizontal-xl > .list-group-item.active {
		margin-top:0
	}

	.list-group-horizontal-xl > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width:0
	}

	.list-group-horizontal-xl > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width:1px
	}
}

@media (min-width: 1400px) {
	.list-group-horizontal-xxl {
		flex-direction:row
	}

	.list-group-horizontal-xxl > .list-group-item:first-child {
		border-bottom-left-radius: .375rem;
		border-top-right-radius:0
	}

	.list-group-horizontal-xxl > .list-group-item:last-child {
		border-top-right-radius: .375rem;
		border-bottom-left-radius:0
	}

	.list-group-horizontal-xxl > .list-group-item.active {
		margin-top:0
	}

	.list-group-horizontal-xxl > .list-group-item + .list-group-item {
		border-top-width: 1px;
		border-left-width:0
	}

	.list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
		margin-left: -1px;
		border-left-width:1px
	}
}

.list-group-flush {
	border-radius:0
}

.list-group-flush > .list-group-item {
	border-width:0 0 1px
}

.list-group-flush > .list-group-item:last-child {
	border-bottom-width:0
}

.list-group-item-primary {
	color: #8cb;
	background-color:#fbd2e0
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
	color: #8cb;
	background-color:#e2bdca
}

.list-group-item-primary.list-group-item-action.active {
	color: #fff;
	background-color: #8cb;
	border-color:#8cb
}

.list-group-item-secondary {
	color: #4a4d5c;
	background-color:#e5e6eb
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
	color: #4a4d5c;
	background-color:#cecfd4
}

.list-group-item-secondary.list-group-item-action.active {
	color: #fff;
	background-color: #4a4d5c;
	border-color:#4a4d5c
}

.list-group-item-success {
	color: #2e6930;
	background-color:#dbefdc
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
	color: #2e6930;
	background-color:#c5d7c6
}

.list-group-item-success.list-group-item-action.active {
	color: #fff;
	background-color: #2e6930;
	border-color:#2e6930
}

.list-group-item-info {
	color: #10458b;
	background-color:#d1e3fa
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
	color: #10458b;
	background-color:#bccce1
}

.list-group-item-info.list-group-item-action.active {
	color: #fff;
	background-color: #10458b;
	border-color:#10458b
}

.list-group-item-warning {
	color: #975400;
	background-color:#fee8cc
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
	color: #975400;
	background-color:#e5d1b8
}

.list-group-item-warning.list-group-item-action.active {
	color: #fff;
	background-color: #975400;
	border-color:#975400
}

.list-group-item-danger {
	color: #922820;
	background-color:#fdd9d7
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
	color: #922820;
	background-color:#e4c3c2
}

.list-group-item-danger.list-group-item-action.active {
	color: #fff;
	background-color: #922820;
	border-color:#922820
}

.list-group-item-light {
	color: #5d5e60;
	background-color:#fbfbfc
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
	color: #5d5e60;
	background-color:#e2e2e3
}

.list-group-item-light.list-group-item-action.active {
	color: #fff;
	background-color: #5d5e60;
	border-color:#5d5e60
}

.list-group-item-dark {
	color: #1f2b3e;
	background-color:#d6dae1
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
	color: #1f2b3e;
	background-color:#c1c4cb
}

.list-group-item-dark.list-group-item-action.active {
	color: #fff;
	background-color: #1f2b3e;
	border-color:#1f2b3e
}

.list-group-item-white {
	color: #666;
	background-color:#fff
}

.list-group-item-white.list-group-item-action:focus, .list-group-item-white.list-group-item-action:hover {
	color: #666;
	background-color:#e6e6e6
}

.list-group-item-white.list-group-item-action.active {
	color: #fff;
	background-color: #666;
	border-color:#666
}
