
.pagination {
    display: -webkit-box;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius:.25rem
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: 0;
    line-height: 1.25;
    color: #9c27b0;
    background-color: transparent;
    border:0 solid #dee2e6
}

.page-link:hover {
    z-index: 2;
    color: #641971;
    text-decoration: none;
    background-color: #e9ecef;
    border-color:#dee2e6
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow:0 0 0 .2rem rgba(33, 150, 243, .25)
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius:.25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius:.25rem
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #2196f3;
    border-color:#2196f3
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: transparent;
    border-color:#dee2e6
}

.pagination-lg .page-link {
    padding: .75rem 0;
    font-size: 1.25rem;
    line-height:1.5
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius:.3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius:.3rem
}

.pagination-sm .page-link {
    padding: .25rem 0;
    font-size: .875rem;
    line-height:1.5
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius:.2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius:.2rem
}

.places-buttons .btn {
    margin-bottom:30px
}

.pagination > .page-item .page-link.mat-button .mat-button-ripple {
    border-radius:30px
}

.pagination .paginate_button a, .pagination > .page-item > .page-link.mat-button, .pagination > .page-item > span {
    border: 0;
    border-radius: 30px !important;
    -webkit-transition: all .3s;
    transition: all .3s;
    padding: 0 11px;
    margin: 0 3px;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    color: #999;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    background: transparent;
    text-align:center
}


 .dataTables_paginate a {
	 border: 0;
    border-radius: 30px !important;
    -webkit-transition: all .3s;
    transition: all .3s;
    padding: 0 11px;
    margin: 0 3px;
    min-width: 30px;
    height: 30px;
    line-height: 30px;
    color: #999;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    background: transparent;
    text-align:center
 }

.dataTables_paginate a .paginate_button current {
background-color: #9c27b0;
border-color: #9c27b0;
color: #fff;
box-shadow: 0 4px 5px 0 rgba(156,39,176,.14),0 1px 10px 0 rgba(156,39,176,.12),0 2px 4px -1px rgba(156,39,176,.2);
}

.dataTables_wrapper .dataTables_filter label {
font-weight: 400;
white-space: nowrap;
text-align: left;
}
select[name="result-table_length"] {
  padding-left:5px;
  padding-right:5px;
  border-top: 1px solid #9124a3;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #9124a3;
}

select[name="DataTables_Table_0_length"] {
  padding-left:5px;
  padding-right:5px;
  border-top: 1px solid #9124a3;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #9124a3;
}
