
 @import "@angular/material/prebuilt-themes/indigo-pink.css";
 @import "@ng-select/ng-select/themes/default.theme.css";
 @import '@angular/material/theming';

 @include mat-core();

$candy-app-primary: mat-palette($mat-blue);
$candy-app-accent:  mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);



 @import "core/variables";
 @import "core/mixins";
 @import "bootstrap/scss/bootstrap";

//
// @import 'material-icons-font/sass/variables';
// @import 'material-icons-font/sass/mixins';
// $MaterialIcons_FontPath: "material-icons-font/fonts";
// @import 'material-icons-font/sass/main';
// @import 'material-icons-font/sass/Regular';

 // Core Components
 @import "core/buttons";
 @import "core/checkboxes";
 @import "core/radios";
 @import "core/forms";
 @import "core/input-group";
 @import "core/list-group";
 @import "core/images";
 @import "core/navbar";
 @import "core/alerts";
 @import "core/type";
 @import "core/tabs";
 @import "core/tooltip";
 @import "core/popover";
 @import "core/dropdown";
 @import "core/togglebutton";
 @import "core/ripples";
 @import "core/footers";
 @import "core/sidebar-and-main-panel";
 @import "core/fixed-plugin";
 @import "core/profile-plugin";
 @import "core/tables";
 @import "core/misc";


@import "core/cards";
@import "core/cards/card-stats";
@import "core/cards/card-profile";
@import "core/cards/card-plain";

 //plugin scss
 @import "core/plugins/animate";
 @import "core/plugins/chartist";
 @import "core/plugins/perfect-scrollbar";

 @import "core/responsive";

@import "core/angular-modal.scss";
