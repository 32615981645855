/* You can add global styles to this file, and also import other style files */
@import "../node_modules/angular-calendar/css/angular-calendar.css";

.pointer {cursor: pointer;}

.bg-item {
	background: #000000 !important;
	color: white !important;
}

.bg-black {
	background: #000000 !important;
	color: white !important;
}

.bold {
	font-weight: bolder;
}

table {
	width: 100%;
}

th.mat-sort-header-sorted {
	color: black;
}

.card-icon.icon-primary {
	color: #9c27b0
}

.card-icon.icon-info {
	color: #00bcd4
}

.card-icon.icon-success {
	color: #4caf50
}

.card-icon.icon-warning {
	color: #ff9800
}

.card-icon.icon-danger {
	color: #f44336
}

.card-icon.icon-rose {
	color: #e91e63
}

.card-icon.icon-white {
	color: #fff
}



.card .card-header-space .card-icon,
.card .card-header-space .card-text,
.card .card-header-space:not(.card-header-icon):not(.card-header-text),
.card.bg-danger,
.card.card-rotate.bg-danger .front,
.card.card-rotate.bg-danger .back {
	background: linear-gradient(60deg, #0965c0, #c53a94)
}


.card .card-header-inventory .card-icon,
.card .card-header-inventory .card-text,
.card .card-header-inventory:not(.card-header-icon):not(.card-header-text),
.card.bg-danger,
.card.card-rotate.bg-danger .front,
.card.card-rotate.bg-danger .back {
	background: linear-gradient(60deg, #5061ef, #870a08);
}



.card .card-header-orderentry .card-icon,
.card .card-header-orderentry .card-text,
.card .card-header-orderentry:not(.card-header-icon):not(.card-header-text),
.card.bg-danger,
.card.card-rotate.bg-danger .front,
.card.card-rotate.bg-danger .back {
	background: linear-gradient(60deg, #30c5d2, #471069)
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
	z-index: 10000 !important;
}

.card .card-header-rec .card-icon,
.card .card-header-rec .card-text,
.card .card-header-rec:not(.card-header-icon):not(.card-header-text),
.card.bg-danger,
.card.card-rotate.bg-danger .front,
.card.card-rotate.bg-danger .back {
	background: linear-gradient(60deg, #0d41e1, #07c8f9);
}

.card .card-header-tt .card-icon,
.card .card-header-tt .card-text,
.card .card-header-tt:not(.card-header-icon):not(.card-header-text),
.card.bg-danger,
.card.card-rotate.bg-danger .front,
.card.card-rotate.bg-danger .back {
	background: linear-gradient(60deg, #8d7001, #642d9c);
}

.card .card-header-purch .card-icon,
.card .card-header-info .card-text,
.card .card-header-purch:not(.card-header-icon):not(.card-header-text),
.card.bg-info,
.card.card-rotate.bg-info .front,
.card.card-rotate.bg-info .back {
	background: linear-gradient(60deg, #0f9136, #986409);
}

.card .card-header-vendor .card-icon,
.card .card-header-vendor .card-text,
.card .card-header-vendor:not(.card-header-icon):not(.card-header-text),
.card.bg-vendor,
.card.card-rotate.bg-vendor .front,
.card.card-rotate.bg-vendor .back {
	background: linear-gradient(60deg, #620095, #e53935);
}

.card .card-header-location .card-icon,
.card .card-header-location .card-text,
.card .card-header-location:not(.card-header-icon):not(.card-header-text),
.card.bg-location,
.card.card-rotate.bg-location .front,
.card.card-rotate.bg-location .back {
	background: linear-gradient(60deg, darkblue, violet);
}

.card .card-header-reorder .card-icon,
.card .card-header-location .card-text,
.card .card-header-reorder:not(.card-header-icon):not(.card-header-text),
.card.bg-reorder,
.card.card-rotate.bg-reorder .front,
.card.card-rotate.bg-location .back {
	background: linear-gradient(60deg, #30c5d2, #471069);
}



.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
	background-color: #d421f4 !important;
	color: white;
}

.sidebar .nav li>a:focus,
.sidebar .nav li>a:hover {
	background-color: rgba(0, 169, 249, 0.97) !important;
	outline: none;
}


.tire-card {
	width: 100%;
	padding: 0;
	margin: 16px 0;
}

.tire-card mat-card-content.padding-0 {
	padding: 0;
}

.tire-card .spec-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.tire-card .spec-list li {
	border-bottom: 1px solid rgba(0, 0, 0, .09);
	-webkit-transition: background 60ms ease-in;
	transition: background 60ms ease-in;
	min-height: 32px;
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	max-height: 100%;
	align-content: stretch;
	align-items: stretch;
	justify-content: flex-start;
}

.tire-card .spec-list li .spec-label {
	background-color: rgba(0, 0, 0, .03);
	color: #444;
	font-weight: 300;
	line-height: 32px;
	padding: 0 8px;
	text-align: right;
	border-right: 1px solid rgba(0, 0, 0, .09);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	flex: 1 1 100%;
	box-sizing: border-box;
	max-width: 45%;
}

.tire-card .spec-list li .spec-value {
	line-height: 32px;
	font-size: 16px;
	font-weight: 500;
	padding: 0 8px;
	flex: 1 1 100%;
	box-sizing: border-box;
	max-width: 60%;
}

.tire-image-container div {
	width: 100%;
	height: 100%;
	flex: 1 1 100%;
	box-sizing: border-box;
	max-height: 100%;
}

.tire-image img,
.tire-noimage img {
	max-height: 100%;
	max-width: 100%;
	margin: 0 auto;
	display: block;
}


.entry-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.entry-list li {
	border-bottom: 1px solid rgba(0, 0, 0, .09);
	-webkit-transition: background 60ms ease-in;
	transition: background 60ms ease-in;
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	max-height: 100%;
	align-content: stretch;
	align-items: stretch;
	justify-content: flex-start;
}

.entry-list li .spec-label {
	background-color: rgba(0, 0, 0, .03);
	color: #222;
	font-weight: 300;
	line-height: inherit;
	font-size: inherit;
	text-align: right;
	border-right: 1px solid rgba(0, 0, 0, .09);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	flex: 1 1 100%;
	box-sizing: border-box;
	max-width: 50%;
	padding: 0 4px;
	white-space: nowrap;
}

.entry-list li .spec-label-small {
	background-color: rgba(0, 0, 0, .06);
	color: #222;
	font-weight: 300;
	line-height: inherit;
	font-size: inherit;
	text-align: right;
	border-right: 1px solid rgba(0, 0, 0, .09);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	flex: 1 1 100%;
	box-sizing: border-box;
	max-width: 85%;
	padding: 0 2px;
	white-space: nowrap;
}

.entry-list li .spec-label-large {
	background-color: rgba(0, 0, 0, .06);
	color: #222;
	font-weight: 300;
	line-height: inherit;
	font-size: inherit;
	text-align: right;
	border-right: 1px solid rgba(0, 0, 0, .09);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	flex: 1 1 100%;
	box-sizing: border-box;
	max-width: 20%;
	padding: 0 2px;
	white-space: nowrap;
}

.entry-list li .spec-value {
	line-height: inherit;
	font-size: inherit;
	font-weight: 500;
	flex: 1 1 100%;
	box-sizing: border-box;
	max-width: 50%;
	padding: 0 4px;
	white-space: nowrap;
}

.entry-list li .spec-value-small {
	line-height: inherit;
	font-size: inherit;
	font-weight: 500;
	flex: 1 1 100%;
	box-sizing: border-box;
	max-width: 15%;
	padding: 0 2px;
	white-space: nowrap;
}

.entry-list li .spec-value-large {
	line-height: inherit;
	font-size: inherit;
	font-weight: 500;
	flex: 1 1 100%;
	box-sizing: border-box;
	max-width: 80%;
	padding: 0 2px;
	white-space: nowrap;
}

.highlight {
	border: 2px solid rgba(0, 0, 0, 0);
}

.highlight:hover {
	border: 2px dotted #d81b60;
}

.highlighted {
	border: 2px dotted #d81b60;
}

.highlighted-solid {
	border: 1px solid #d81b60;
	padding: 2px;
}

.noflow {
	overflow: hidden;
}
.form-inputs {
	font-size: 90%;
}
.text-dark {
	color: #0e0f0f !important;
}

table {
  width: 100%;
}

th.mat-sort-header-sorted {
  color: black;
}

tr.mat-header-row {
	height: auto !important;
}

.mat-sort-header-content {
	color:#000 !important;
	font-weight: 500 !important;
}


.mat-cell, .mat-footer-cell {
  color: #0e0f0f;
}

.table th, .table td {
padding: 0rem;
vertical-align: middle;
border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.table th {
	background: #f3f4f4;
	padding: 2px !important;
	margin:5px !important;
}

.table td {
	padding: 2px !important;
	margin: 5px !important;
}

.table-sm  {

}

.htmlinvtable td {
vertical-align:bottom;
}

.htmlinviq th {
    font-weight: bold;
    background-color:#bbbbbb;

}
.htmlinviq td {
    background-color:#eeeeee;
}
.htmlinvitemtable {

    width:100%;
}
.htmlinvitemtable th{

    background-color:#222222;
    color: whitesmoke;
	text-align:center;
}
.htmlinvtotaltable {

    width:100%;
}
.htmlinvtotaltable th{

    font-weight:bold;
    text-align:right;
}
.htmlinvtotaltable td{
    text-align:right;
    background-color:#eeeeee;
    width:15%;
}
.allocviewtable td {
    vertical-align:top;

}
.no-overflow {
	overflow: hidden !important;
}

.iframe-container {
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
}

 .table-container {
	max-height: calc(100vh - 155px);
	overflow: auto;
  }

.table-fixed thead {
	  position: sticky !important;
	  top: 0;
}

.table-fixed thead tr th {
	  position: sticky !important;
	  top: 0;
}

.table .table-fixed > tbody > tr {
	position: inherit;
}

.fixTableHead {
	overflow-y: auto;
	height: 100vh;
}
.fixTableHead thead th {
	position: sticky;
	top: 0;
	font-weight: 900;
}

.fixTableHead tobdy td {
	line-height: 14px;
}

.mat-row:hover {
  background-color: rgb(211, 211, 211);
}

.donotshow {
	display:none !important;
}

.pie-a {
	fill: #8e8e8e !important;
	background: #8e8e8e !important;
}
.pie-b {
	fill: #aaaaaa !important;
	background: #aaaaaa!important;
}
.pie-c {
	fill: #f7f7f7 !important;
	background: #f7f7f7 !important;
}
.pie-d {
	fill: #eaeaea !important;
	background: #eaeaea!important;
}
.pie-e {
	fill: #cecece !important;
	background: !important;
}
.pie-f {
	fill: #c1c1c1 !important;
	background: #cecece !important;
}
.pie-g {
	fill: #939393 !important;
	background:#939393 !important;
}
.pie-h {
	fill: #bababa !important;
	background: !important;
}
.pie-i {
	fill: #afafaf !important;
	background:#bababa !important;
}
.pie-j {
	fill: #8e8e8e !important;
	background: #8e8e8e !important;
}
.pie-k {
	fill: #dddddd !important;
	background:#dddddd !important;
}
.pie-l {
	fill: #a3a3a3 !important;
	background:#a3a3a3 !important;
}
.pie-m {
	fill: #9e9e9e !important;
	background:#9e9e9e !important;
}
.pie-n {
	fill: #adadad !important;
	background:#adadad !important;
}
.pie-o {
	fill: #efefef !important;
	background: !important;
}
.pie-p {
	fill: #dddddd !important;
	background:#efefef !important;
}
.pie-q {
	fill: #eaeaea !important;
	background:#eaeaea !important;
}
.pie-r {
	fill: #9b9b9b !important;
	background:#9b9b9b !important;
}
.pie-s {
	fill: #e0e0e0 !important;
	background: #e0e0e0 !important;
}
.pie-t {
	fill: #a9a9a9 !important;
	background:#a9a9a9 !important;
}
.pie-u {
	fill: #999999 !important;
	background:#999999 !important;
}
.pie-v {
	fill: #bcbcbc !important;
	background: !important;
}
.pie-w {
	fill: #f7f7f7 !important;
	background:#bcbcbc !important;
}
.pie-x {
	fill: #afafaf !important;
	background:#afafaf !important;
}
.pie-y {
	fill: #8c8c8c !important;
	background: #8c8c8c!important;
}
.pie-z {
	fill: #a3a3a3 !important;
	background: #a3a3a3 !important;
}

.flashing{
	animation-duration: 1.5s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
}

.flash{
	animation-name: flash;
}
@keyframes flash {
	0%, 50%, 100% {
		opacity: 1;
	}
	25%, 75% {
		opacity: 0;
	}
}
.noshow {
	display:none;
}

.ct-label-small {
	color: #000 !important;
	font-size: .65em !important;
}

.select-all-container {
  display: flex;
  justify-content: left;
  margin-bottom: 8px;
}

.select-all-button {
  background-color: #007bff; /* Adjust button color */
  color: #fff; /* Text color */
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.select-all-button:hover {
  background-color: #0056b3; /* Hover color */
}

.custom-mat-panel .mat-select-panel {
  max-height: 60vh !important;
}
